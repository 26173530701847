<template>
  <div class="sign_box" ref="wheelRef" v-loading="loading">
    <el-dialog v-model="visibleTrunable" class="turnable_dialog" title="" :show-close="false" width="1200" center>
      <div v-pre-img class="dialog-main">
        <el-row>
          <div class="wheel">
            <img src="@/assets/turnable/dice.png" alt="" class="move-img move-img1">
            <img src="@/assets/turnable/envelope.png" alt="" class="move-img move-img2">
            <div class="wheel-main">
              <LuckyWheel ref="myLucky" width="346px" height="346px" :defaultConfig="wheelState.defaultConfig"
                :prizes="wheelState.prizes" :blocks="wheelState.blocks" :buttons="wheelState.buttons"
                @start="startCallback" @end="endCallback" />
            </div>
          </div>

          <div class="detail">
            <div v-pre-img class="link-box">
              <div class="link-btn">
                <el-button @click="showRules" class="link">活动说明</el-button>
                <el-button @click="showRecords" class="link">抽奖记录</el-button>
              </div>
              <div class="count-box">还可抽奖：<span class="count">{{ statusInfo.notuseJoinCount || 0 }}</span>次</div>
            </div>
            <div v-pre-img class="detail-main">
              <div class="detail-page">
                <div v-pre-img class="btn-box">
                  <div class="btn">今日存款合计</div>
                  <div class="btn">
                    <div v-pre-img class="btn1" @click="goDeposit"></div>
                  </div>
                </div>
                <div class="total-box">
                  <div class="money">
                    <span class="money1">¥</span>
                    <span class="money2">{{ numFormat(statusInfo.totalDeposit) }}</span>
                  </div>
                  <div class="txt" style="display: flex;">
                    <span class="text">当前距下次抽奖的存款完成进度：</span>
                    <span class="text-val">{{ len ?? '--' }}%</span>
                  </div>
                  <div class="progress">
                    <div class="progress-len">
                      <div class="progress-bar" :style="{ width: len + '%' }"></div>
                    </div>
                  </div>
                  <div class="txt" v-if="statusInfo.totalDeposit < statusInfo.notFinishTotalDeposit">
                    领取下次抽奖机会您还需充值<span class="text-val">￥{{ statusInfo.notFinishNeedDeposit.toFixed(2) || '-'
                      }}元</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-row>

        <div class="close_con" @click="visibleTrunable = false">
          <el-icon>
            <CircleClose color="#fff" />
          </el-icon>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="showOpenRes" class="res_dialog" title="" :show-close="false" width="1200" center>
      <template #header></template>
      <div class="res-box">
        <div style="width: 478px;height: 570px;"
          :style="{ backgroundImage: 'url(' + resImgMap[openRes.prizeType] + ')', backgroundRepeat: 'no-repeat', backgroundSize: '100% auto' }">
          <div class="prize-text">
            <div class="prize-val">{{ openRes.prizeType === 2 ? '坚持就是胜利' : openRes.prizeName }}</div>
          </div>
        </div>
        <div class="close" @click="showOpenRes = false">
          <el-icon color="#fff" size="60px">
            <CircleClose />
          </el-icon>
        </div>
      </div>
    </el-dialog>

    <el-dialog v-model="rulesVisiable" class="rules_dialog" title="活动详情" :show-close="true" center align-center>
      <el-scrollbar max-height="70vh" always>
        <div class="content" v-html="rouletteDetails"></div>
      </el-scrollbar>
    </el-dialog>

    <el-dialog v-model="recordsVisiable" class="records_box" title="抽奖记录" :show-close="true" width="620" center>
      <div class="form_item">
        <div class="label">选择日期</div>
        <el-date-picker style="width: 220px; flex-grow: inherit;margin: 10px;" v-model="time" :clearable="false"
          type="daterange" unlink-panels range-separator="~" start-placeholder="起始时间" end-placeholder="截止时间"
          format="YYYY-MM-DD" value-format="YYYY-MM-DD" :shortcuts="shortcuts" />
        <el-button type="primary" @click="getRecords">查询</el-button>
      </div>
      <div class="table_cont">
        <el-table :data="lists" style="width: 100%">
          <el-table-column prop="createTime" label="参与时间" min-width="120px" align="center" />
          <el-table-column prop="prizeName" label="奖品" min-width="120px" align="center" />
        </el-table>
        <div class="page_box">
          <el-row justify="center" align="middle" style="padding: 10px" class="page_cont">
            <el-pagination :total="totalCount" v-model:page-size="queryParams.limit"
              :page-sizes="[10, 20, 50, 100, 200]" @size-change="handleSizeChange" @change="pageChange"
              layout=" total,sizes,prev, pager, next" ref="pageRef" prev-text="上一页" next-text="下一页">
            </el-pagination>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { rouletteStatus, roulettePost, rouletteRecords, rouletteDetils, wheelList } from '@/api/index.js'
import { ElMessage } from 'element-plus'
import { LuckyWheel } from '@lucky-canvas/vue'
import dayjs from 'dayjs'
import { shortcuts } from '@/utils/dateUtils'
import { numFormat } from '@/utils/tools'
import cloneDeep from 'lodash.clonedeep'

import vPreImg from '@/directives/v-preImg'
import useAllImageLoaded from '@/hooks/preloaderImg.hook'
const router = useRouter()

const loading = ref(false)
const rouletteDetails = ref('')
const visibleTrunable = ref(false)

import pointerImg from '@/assets/turnable/pointer.png'
import pointerDisabledImg from '@/assets/turnable/pointer_disabled.png'
import giftMoney from "@/assets/turnable/gift_money.png"
import giftPhysical from "@/assets/turnable/gift_physical.png"
import giftEmpty from "@/assets/turnable/gift_empty.png"
import resWin from "@/assets/turnable/res_win.png"
import resGift from "@/assets/turnable/res_gift.png"
import resFail from "@/assets/turnable/res_fail.png"
import wheelInner from "@/assets/turnable/wheel_inner.png"
import wheelBg from "@/assets/turnable/wheel_bg.png"
const wheelState = reactive({
  defaultConfig: {
    // gutter: '0',
    // stopRange: 0.5,
    // offsetDegree: '',
    decelerationTime: 5500,
  },
  blocks: [{ imgs: [{ src: wheelInner, width: '346px', height: '346px', rotate: true }] }],
  prizes: [],
  buttons: [{
    radius: '152px',
    imgs: [{ src: pointerDisabledImg, top: '-132px', width: '152px', height: '208px' }]
  }],
  initPrize: {
    fonts: [{ text: '0', top: '8px', fontColor: '#9A4950', fontSize: '16px' }],
    imgs: [{ src: '', top: '40px', width: '40px', height: '40px' }]
  }
})
const len = ref(0)
const myLucky = ref()
const prizeImgMap = {
  '0': giftMoney,
  '1': giftPhysical,
  '2': giftEmpty,
}
const resImgMap = {
  '0': resWin,
  '1': resGift,
  '2': resFail,
}
const openRes = ref({})
const statusInfo = ref({})
const showOpenRes = ref(false)

// 执行预加载
const imgList = [wheelBg, pointerImg, pointerDisabledImg, wheelInner, resWin, resGift, resFail, giftMoney, giftPhysical, giftEmpty]
const wheelRef = ref()
useAllImageLoaded(wheelRef, imgList)

const props = defineProps({ // 轮盘类型
  type: {
    type: String,
    default: 'turnable'
  }
})

const showDialog = () => {
  getData()
  getPrizeList()
}
const getData = async () => {
  loading.value = true
  let { result: res } = await rouletteStatus(props.type)
  statusInfo.value = res.data
  const { totalDeposit, notFinishTotalDeposit } = res.data
  len.value = totalDeposit < notFinishTotalDeposit ? (totalDeposit / notFinishTotalDeposit * 100).toFixed(2) : 100
  setPointerImg()
  visibleTrunable.value = true
  loading.value = false
}

const rulesVisiable = ref(false)
const showRules = async () => {
  let { result } = await rouletteDetils(props.type)
  rouletteDetails.value = result.data.detail
  rulesVisiable.value = true
}

const recordsVisiable = ref(false)
const showRecords = async () => {
  recordsVisiable.value = true
  getRecords()
}

const lists = ref([])
const queryParams = ref({
  beginTime: dayjs().format('YYYY-MM-DD'),
  endTime: dayjs().format('YYYY-MM-DD'),
  limit: 10,
  page: 1
})
const time = ref([dayjs().subtract(1, 'months').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')])


const totalPages = ref(0)
const totalCount = ref(0)
const handleSizeChange = (num) => {
  queryParams.value.limit = num
  getRecords()
}
const pageChange = (current) => {
  queryParams.value.page = current
  getRecords()
}
const getRecords = async () => {
  loading.value = true
  let params = {
    ...queryParams.value,
    beginTime: time.value[0],
    endTime: time.value[1]
  }
  let { result } = await rouletteRecords(props.type, params)
  lists.value = result.data.list
  totalPages.value = result.data.totalPage
  totalCount.value = result.data.totalCount
}

const goDeposit = () => {
  visibleTrunable.value = false
  router.push('/memberCentre/deposit')
}


function setPointerImg() {
  wheelState.buttons[0].imgs[0].src = statusInfo.value.notuseJoinCount > 0 ? pointerImg : pointerDisabledImg
}

function setPrizeList(list) {
  wheelState.prizes = list.map((item, index) => {
    const { id, type, name } = item
    let temp = cloneDeep(wheelState.initPrize)
    temp.id = id
    temp.fonts[0].text = name
    temp.imgs[0].src = prizeImgMap[type]
    temp.background = index % 2 == 0 ? '#FFDA95' : ''
    // temp.background = 'linear-gradient(#e66465, #9198e5);'
    // background: radial-gradient(50% 50% at 50% 50%, #FFFAFA 50%, #FFF2BB 100%);
    return temp
  })
}

async function getPrizeList() {
  const { result } = await wheelList()
  if (result) {
    setPrizeList(result.data)
  }
}

async function getTurnClaim() {
  openRes.value = {}
  const { result } = await roulettePost('turnable')
  if (result) {
    // 调用抽奖组件的play方法开始游戏
    unref(myLucky).play()
    getData()
    const { prizeId } = result.data
    openRes.value = result.data
    const _index = wheelState.prizes.findIndex(item => item.id == prizeId)
    if (_index > -1) {
      setTimeout(() => {
        // 调用stop停止旋转并传递中奖索引
        unref(myLucky).stop(_index)
      }, 3000)
    }
  }
}

function startCallback() {
  if (statusInfo.value.notuseJoinCount > 0) {
    getTurnClaim()
  } else {
    ElMessage.error('抱歉，无抽奖次数')
  }
}
function endCallback() {
  showOpenRes.value = true
}

defineExpose({ showDialog })
</script>
<style lang="scss" scoped>
.sign_box {
  :deep(.turnable_dialog) {
    padding: 0 !important;
    background: transparent;

    .dialog-main {
      min-height: 750px;
      background: url('@/assets/turnable/wheel_bg.png') no-repeat;
      background-size: 100% auto;
    }

    .el-dialog__body {
      padding: 0;
    }

    .wheel {
      margin-top: 160px;
      margin-left: 100px;
      width: 450px;
      height: 450px;
      padding: 52px;
      position: relative;
      float: left;

      .wheel-main {
        cursor: pointer;
      }

      .move-img1 {
        width: 55px;
        height: 55px;
        position: absolute;
        top: 22px;
        left: -9px;
        animation: upAndDownShake 4s infinite;
      }

      .move-img2 {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 45px;
        right: -12px;
        animation: upAndDownShake 5.5s infinite;
      }
    }

    .detail {
      float: left;
      margin: 102px 0 0 140px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .link-box {
        width: 400px;
        height: 108px;
        background: url('@/assets/turnable/link_bg.png') no-repeat;
        background-size: 100% auto;

        .link-btn {
          padding: 4px;
          display: flex;
          align-items: center;

          .link {
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #E2C0FF;
            flex: 1;
            font-size: 24px;
            background: transparent;
            border: 0;
            margin: 0;

            &:hover {
              color: #fff;
            }
          }
        }
      }

      .count-box {
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-size: 24px;
        color: #E2C0FF;

        .count {
          color: #F7CD21;
          font-weight: 600;
        }
      }

      .detail-main {
        width: 460px;
        margin-top: 42px;
        padding-top: 96px;
        background: url('@/assets/turnable/detail.png') no-repeat;
        background-size: 100% auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .detail-page {
          width: 420px;
          background: linear-gradient(0deg, #f0f3fa 0%, #f0f3fa 94%, #4c365b 100%);
          border-radius: 0 0 20px 20px;
          padding: 26px 10px 28px 10px;

          .btn-box {
            height: 46px;
            background-image: url("@/assets/turnable/total_bg.png");
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: 100% 0;
            display: flex;

            .btn {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #565656;
              font-size: 24px;

              .btn1 {
                margin-top: 9px;
                background-image: url("@/assets/turnable/btn_bg.png");
                background-repeat: no-repeat;
                background-size: auto 100%;
                background-position: 50% 50%;
                width: 150px;
                height: 38px;
                cursor: pointer;
              }
            }

          }

          .total-box {
            border-radius: 10px 0 0 0;
            background: linear-gradient(0deg, rgba(240, 243, 250, 1) 0%, rgba(240, 243, 250, 1) 50%, rgba(243, 224, 240, 1) 100%);
            overflow: hidden;
            padding: 0 9px;

            .money {
              background: #FFCFEE80;
              width: 366px;
              height: 50px;
              border-radius: 9999px;
              box-shadow: 0px 6px 6px 0px #0000000D inset;
              margin: 0 auto;
              margin-top: 34px;
              margin-bottom: 10px;
              color: #9131BD;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 36px;

              .money1 {
                margin-right: 10px;
              }

              .money2 {
                font-weight: 600;
              }
            }

            .txt {
              color: #565656;
              font-size: 16px;
              line-height: 24px;

              .text-val {
                color: #9131BD;
                font-size: 24px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }


    .close_con {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: -40px;
      font-size: 32px;
    }

    .progress {
      margin: 10px 0;
      // padding: 10px 0;
      height: 30px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 16px;
        background-color: #E1E1E1;
        border-radius: 99999px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }

      .progress-len {
        width: 100%;
        height: 16px;
        padding-left: 8px;
        padding-right: 34px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
      }

      .progress-bar {
        height: 100%;
        background: url('@/assets/turnable/progress.png') repeat;
        background-size: auto 100%;
        position: relative;

        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 16px;
          position: absolute;
          left: -7px;
          top: 50%;
          transform: translateY(-50%);
          background: url('@/assets/turnable/progress_start.png') no-repeat;
          background-size: auto 100%;
        }

        &::after {
          content: '';
          display: block;
          width: 34px;
          height: 30px;
          position: absolute;
          right: -34px;
          top: 50%;
          transform: translateY(-50%);
          background: url('@/assets/turnable/progress_mark.png') no-repeat;
          background-size: auto 100%;
        }
      }
    }
  }

  :deep(.res_dialog) {
    background: rgba(0, 0, 0, 0.7);

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 90px 0;
    }

    .res-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .close {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        cursor: pointer;
      }
    }

    .prize-text {
      margin-top: 90px;
      color: #FCE5B1;
      font-size: 32px;
      text-align: center;

      .prize-val {
        min-width: 246px;
        background: #FFFFFF26;
        // border: 2px solid #FCE5B14D;
        border-radius: 9999px;
        padding: 0;
        display: inline-block;
        line-height: 50px;
        transform: skew(-20deg) rotate(-3deg);
      }
    }
  }

  :deep(.rules_dialog) {
    padding: 0;

    .el-dialog__body {
      padding: 20px 10px;
    }

    .content {
      max-width: 80vw;
      margin: 0 20px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  :deep(.records_box) {
    .el-dialog__header {
      text-align: left;
    }

    .el-dialog__body {
      .form_item {
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
          color: #5f5f5f;
        }
      }

      .table_cont {
        margin-top: 20px;

        .el-table {
          th.el-table__cell {
            background-color: #f5f5f5;
            color: #999999;
          }

          .el-table__body-wrapper {
            border-radius: 4px;
          }
        }

        .btn-prev,
        .btn-next {
          width: 60px;
          border-radius: 5px;
          height: 30px;
          display: flex;
          margin-left: 10px;
          align-items: center;
          border: solid 1px #d9dadc;
          background-image: linear-gradient(to bottom, #fff, #eeeeef);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.inner-dialog {
  .el-dialog {
    background: transparent;
    box-shadow: none;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
      width: 259px;
      height: 271px;

      background: transparent;

      section {
        width: 100%;
        height: 100%;
        color: #eb162e;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: url('@/assets/turnable/pack.png') no-repeat center center;
        background-size: 100%;

        h2 {
          padding-top: 15px;
        }

        p {
          font-size: 14px;
          margin-top: 30px;
          color: #ff512e;

          &.line {
            width: 183px;
            height: 9px;
            background: url('@/assets/turnable/line.png') no-repeat center center;
            background-size: 100%;
            margin: 10px 0;
          }
        }

        .result {
          font-size: 32px;
          color: #ff512e;

          strong {
            font-weight: 600;
          }
        }

        .btn {
          width: 130px;
          height: 38px;
          border-radius: 19px;
          border: solid 1px #fff2ce;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff2ce;
          margin-top: 62px;
          cursor: pointer;
        }
      }
    }
  }

}
</style>
